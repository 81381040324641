export const colorCategories = [
    { id: 1, name: "ネイビー/ブルー", color: "lightblue" },
    { id: 2, name: "ベージュ", color: "tan" },
    { id: 3, name: "シルバー", color: "silver" },
    { id: 4, name: "レッド/ピンク", color: "pink" },
    { id: 5, name: "バイオレット", color: "violet" },
    { id: 6, name: "グレー", color: "gray" },
    { id: 7, name: "グリーン", color: "green" },
];

interface ColorCategory {
    id: number;
    name: string;
    color: string;
}

export type { ColorCategory };

export const getColorCategoryFromCategoryId = (colorCategoryId: number) => {
    const matchedCategory = colorCategories.find((cat) => cat.id === colorCategoryId);
    return matchedCategory;
};
